<template>
  <el-row class="displays">
    <el-col :span="24">
      <div class="pages hidden-xs-only">
        <div class="banner">
          <img src="@/assets/images/accunt/intr1.png" alt="" />
          <div
            class="title"
            :class="[{ weibei: lang }, loc == 'en' ? 'Widths' : '']"
          >
            {{ $t("introduce").title }}
          </div>
        </div>
        <div class="textConter">
          <div class="conter1">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("introduce").title }}
            </div>
            <div class="text">
              {{ $t("introduce").t1 }}
            </div>
            <div class="list">
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r1.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r1 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r2 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r3 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r2.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r4 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r5 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r6 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r3.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r7 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r8 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r9 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r4.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r10 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r11 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r12 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r5.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r13 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r14 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r15 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r6.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r16 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r17 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r18 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img
                    style="width: 75%"
                    src="@/assets/images/accunt/r10.png"
                    alt=""
                  />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r19 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r20 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r21 }}</span>
                  </div>
                </div>
              </div>
              <div class="item" v-if="0">
                <div class="left">
                  <img src="@/assets/images/accunt/r11.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r22 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r23 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r24 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r12.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r25 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r26 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r27 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img
                    src="@/assets/images/accunt/r13.png"
                    alt=""
                    style="margin-top: -20px"
                  />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r28 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r29 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r30 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img
                    src="@/assets/images/accunt/r14.png"
                    alt=""
                    style="margin-top: -10px"
                  />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r31 }}</div>
                  <div class="k_value" v-if="0">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r32 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r33 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="conter2">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("introduce").end }}
            </div>
            <div class="map">
              <img :src="$t('introduce').map" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="pages1 hidden-sm-and-up">
        <div class="banner">
          <img src="@/assets/images/accunt/intr2.png" alt="" />
          <div class="title" :class="{ weibei: lang }">
            {{ $t("introduce").title }}
          </div>
        </div>
        <div class="textConter">
          <div class="conter1">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("introduce").title }}
            </div>
            <div class="text">
              {{ $t("introduce").t1 }}
            </div>
            <div class="list">
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r1.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r1 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r2 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r3 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r2.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r4 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r5 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r6 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r3.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r7 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r8 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r9 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r4.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r10 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r11 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r12 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r5.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r13 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r14 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r15 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r6.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r16 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r17 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r18 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img
                    style="margin-top: -9px"
                    src="@/assets/images/accunt/r10.png"
                    alt=""
                  />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r19 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r20 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r21 }}</span>
                  </div>
                </div>
              </div>
              <div class="item" v-if="0">
                <div class="left">
                  <img src="@/assets/images/accunt/r11.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r22 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r23 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r24 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r12.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r25 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r26 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r27 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r13.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r28 }}</div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r29 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r30 }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/r14.png" alt="" />
                </div>
                <div class="right">
                  <div class="name">{{ $t("introduce").r31 }}</div>
                  <div class="k_value" v-if="0">
                    <span class="na_title">{{ $t("introduce").gj }}</span
                    ><span class="value">{{ $t("introduce").r32 }}</span>
                  </div>
                  <div class="k_value">
                    <span class="na_title">{{ $t("introduce").zw }}</span
                    ><span class="value">{{ $t("introduce").r33 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="conter2">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("introduce").end }}
            </div>
            <div class="map">
              <img :src="$t('introduce').map" alt="" />
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      loc: "",
    };
  },
  mounted() {
    this.loc = localStorage.getItem("locale");
  },
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  methods: {},
};
</script>
<style lang="stylus" scoped>
.displays {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Pageconter {
  margin: auto;
}

.pages {
  width: 100%;

  .banner {
    width: 100%;
    background: #405E3C;
    position: relative;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -130px;
      margin-top: -40px;
      width: 260px;
      height: 80px;
      background: rgba(141, 198, 63, 0.9);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 42px;
      color: #FFFFFF;
    }

    .Widths {
      width: 400px;
      margin-left: -200px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  .textConter {
    width: 100%;
    max-width: 1520px;
    margin: 69px auto 97px auto;
    padding: 0 60px;
    box-sizing: border-box;

    .conter1 {
      margin-bottom: 57px;

      .title {
        font-weight: 700;
        font-size: 28px;
        color: #405E3C;
        text-align: left;
        margin-bottom: 37px;
      }

      .text {
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        text-align: left;
      }

      .list {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;
        margin-top: 44px;

        .item {
          width: 40%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 27px 0 27px 32px;
          margin-bottom: 30px;

          &:nth-of-type(2n) {
            margin-left: 10%;
          }

          .left {
            width: 96px;
            height: 96px;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .right {
            flex: 1;
            margin-left: 25px;

            .name {
              font-weight: 700;
              font-size: 20px;
              color: #333333;
              text-align: left;
              margin-bottom: 15px;
            }

            .k_value {
              text-align: left;
              margin-bottom: 11px;
              line-height: 22px;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;

              .na_title {
                font-weight: 700;
                font-size: 18px;
                color: #8DC63F;
                margin-right: 4px;
              }

              .value {
                flex: 1;
                font-weight: 700;
                font-size: 16px;
                color: #5D7E58;
              }
            }
          }
        }
      }
    }

    .conter2 {
      .title {
        font-weight: 700;
        font-size: 28px;
        color: #405E3C;
        text-align: left;
        margin-bottom: 40px;
      }

      .map {
        width: 100%;
        background: #405E3C;
        // height: 756.93px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 90%;
          margin-top: -10px;
        }
      }
    }
  }
}

.pages1 {
  width: 100%;

  .banner {
    width: 100%;
    height: 190px;
    background: #405E3C;
    position: relative;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -60px;
      margin-top: -25px;
      width: 120px;
      height: 50px;
      background: rgba(141, 198, 63, 0.9);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      color: #FFFFFF;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .textConter {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 33px;
    padding: 0 10px;
    box-sizing: border-box;

    .conter1 {
      margin-bottom: 20px;

      .title {
        font-weight: 700;
        font-size: 20px;
        color: #405E3C;
        text-align: left;
        margin-bottom: 23px;
      }

      .text {
        font-size: 14px;
        line-height: 21px;
        color: #333333;
        text-align: left;
      }

      .list {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;
        margin-top: 22px;

        .item {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 10px;
          box-sizing: border-box;
          margin-bottom: 30px;

          .left {
            width: 60px;
            height: 60px;
            margin-top: 10px;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .right {
            flex: 1;
            margin-left: 15px;

            .name {
              font-weight: 700;
              font-size: 16px;
              color: #333333;
              text-align: left;
              margin-bottom: 10px;
            }

            .k_value {
              text-align: left;
              margin-bottom: 4px;
              line-height: 22px;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;

              .na_title {
                font-weight: 700;
                font-size: 14px;
                color: #8DC63F;
                margin-right: 4px;
              }

              .value {
                flex: 1;
                font-weight: 700;
                font-size: 14px;
                color: #5D7E58;
              }
            }
          }
        }
      }
    }

    .conter2 {
      .title {
        font-weight: 700;
        font-size: 20px;
        color: #405E3C;
        text-align: left;
        margin-bottom: 30px;
      }

      .map {
        width: 100%;
        background: #405E3C;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          margin-top: -10px;
        }
      }
    }
  }
}
</style>